
body {
    font-family: "Poppins", sans-serif;
    padding: 0;
    margin: 0;
  
    height: 100%;
    overflow: auto;
    background-color: #EEE3FF;
  }
.login-body{
    background-color: #EEE3FF;
    height: 100vh
}
/* login-page-start */
.main-login{
    padding: 100px;
    }
    
    .login-page-bg{
        width: 550px;
        border-radius: 10px;
    }
    
    .brand-logo{
        background-color: #06B5C9;
        padding: 30px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      
    }
    
    .form-control{
        position: relative;
    }
    
    
    .login-inputs{
        padding: 40px 40px 40px 40px;
        background-color: #fff;
        
    }
    
    .login-inputs .form-floating{
        width: 413px;
    }
    
    
    .login-inputs .form-control{
        background-color: #ececec;
        border: none;
        margin-bottom: 40px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    
    .login-inputs .form-control:focus{
        box-shadow:none
    }
    .login-inputs .input-group-text{
        height: 58px;
        border: none;
        background-color: #7ebbfa;
        padding: 0px 20px 0px 20px;
        font-size: 20px;
        z-index: 9999;
        
    }
    .btn-signin:hover{
      background-color: #007c8a ;
      color: #fff;
    }
.btn-signin{
    width: 170px;
    height: 50px;
    border-radius: 5px;
        background-color: #06B5C9;
        color: white;
    border: none;
    font-weight: 500;
    font-size: 18px;
    }
    
    .btn-forgot{
        background-color: #7ebbfa;
        padding: 10px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        font-size: 20px;
    
    }
    
    .btn-forgot .btn-primary{
        font-size: 20px;
        background-color: inherit;
        border: none;
        color:black;
        font-weight: 500;
    }
   
    .login-inputs .input-group-text {
     
        border: none;
        background-color: #2490eb;
        padding: 0px 20px 0px 20px;
        font-size: 20px;
        z-index: 9999;
      }
      .login-inputs .form-floating .form-control {
        background-color: #ececec;
        border: none;
        margin-bottom: 40px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

      }
     
    .form-floating>label {
        padding-top: 0.5rem !important;
    }
    .form-floating>.form-control:focus~label::after{
        background-color: inherit;
    }
    .form-floating>.form-control, .form-floating>.form-control-plaintext, .form-floating>.form-select {
        height: calc(2.5rem + calc(var(--bs-border-width)* 2)) !important;
        min-height: calc(2.8rem + calc(var(--bs-border-width)* 2)) !important;
        line-height: 1.25;
    }
    .form-floating>.form-control-plaintext~label::after, .form-floating>.form-control:focus~label::after, .form-floating>.form-control:not(:placeholder-shown)~label::after, .form-floating>.form-select~label::after {
        background-color:inherit;
    }
    /* login-page-end */
