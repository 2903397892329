body{
  height: 100vh;
  }
  
  .admin-panel-header
  {background-color:white;
   min-height: 60px;
   box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
   padding: 20px;
  }
  
  .left-nav{ background-color: #0F1821;
  width:200px;
height: 100vh;
   }
   
  .right-sec{
     background: #f7f7ff;
     padding:16px;
  }

  ul,li{
      padding: 0;
      list-style: none;
  }

  .left-links li{
      color: #fff;
  }
  .border-bottom{
      border-bottom: 1px solid #949494!important;
  }
  .text-left-a{
      font-style: italic;
  }
  ul{
      padding-left: 0px !important;
  }
  .nav-item{
      font-size: 12px;
  }
  .vedios-cards {
      background-color: #fff;
      width: ;
      padding: 40px;
   border-radius: 5px;
  }
  .copy-input-container .form-control {
      border-radius: 0.25rem 0 0 0.25rem; /* Rounded corners for input */
  }
  .copy-input-container .btn-copy {
      border-radius: 0 0.25rem 0.25rem 0; /* Rounded corners for button */
  }

  .dropdown-toggle-custom {
      color: #06B5C9 !important;
      border-color: #06B5C9 !important;
  }
  .dropdown-toggle-custom:hover {
      background-color: #e5f7f8; 
  }
  .cards-doxy{
      padding: 20px;
      background-color: #fff;
     border-radius: 5px;
  }
  .doxy-t h6{
      font-size: 12px;
  }
  .roxy-card-w{
   width: 891px;;
  }

  .vedios-roxy{
      padding: 20px;
      background-color: #0F1821;
      border-radius: 5px;
  }
  .btn-primary{
      background-color: #06B5C9!important;
      border: none !important;
      border-radius: 2px !important;
      color: #000 !important;
  }
  .roxy-btn .btn-primary{
      width: 100%;
  }
  .video-container {
      position: relative;
      width: 100%;
      height: auto;
    }
    
    .icon-overlay {
      position: absolute;
      top: 10px; /* Adjust the position as needed */
      right: 10px; /* Adjust the position as needed */
      display: flex;
      gap: 10px; /* Adjust spacing between icons */
      color: white; /* Icon color */
      z-index: 1; /* Make sure it's on top of the video */
    }
    
    .icon {
      font-size: 18px; /* Adjust the size as needed */
      cursor: pointer; /* Make it look clickable */
    }
    
    .mic-icon, .camera-icon {
      /* Additional styling for microphone and camera icons */
      background-color: rgba(253, 25, 25, 0.925); 
      padding: 8px;
      border-radius: 50%;
    }
    .logout-btn{
      border: none;
      border-radius: 2px;
      background-color: #06B5C9;
    }
    .logout-btn:hover{
      background-color: #E98607;
      color: #fff;
      transition: .3s ease;
    }
    .btn-primary:hover{
      background-color: #E98607 !important;
      color: #fff !important;
      transition: .3s ease;
    }
    /* Add these styles to your CSS file */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  border-radius: 10px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 20px;
}





.continue-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}
/* vedi-start   */
.modal-content{
width: 810px!important;
}
.vedio-content-sec {
padding: 30px;
background-color: #fff;
width: 50%;
height: 300px !important;
}

.video-container{
margin: 0px;
height: 348px !important;
width: 100px;

}
.vedio-roxy-card{
background-color: #fff;
padding: 10px;
border-radius: 5px;
}
.off-on-avail{
padding: 5px;
background-color: #CBECD9;
text-decoration: none;
border-radius: 5px;
}
.pending-users-list {
list-style-type: none;
padding: 0;
margin: 0;
}

.pending-users-list li {
border-bottom: 1px solid #ddd;
position: relative;
border-radius: 2px;
}

.waiting-time {
color: #888;
font-size: 0.8em;

}
.main-call-btn:hover .user-roxy {
display: none;
}
.main-call-btn:hover .waiting-time {
display: none;
}
.main-call-btn:hover .start-call-btn {
  margin: 4px;
display: block;
}
.start-call-btn{
display: none;
font-size: 10px !important;

}
.main-call-btn:hover{
background-color: hsla(0, 0%, 100%, .1);
}
.main-call-btn{
margin-bottom: 2px;
padding: 3px;
}

.vedio-table th{
background-color: #06B5C9;
}
.css-josmd9-MuiPaper-root {
margin: 10px 20px;
}
h2{
font-size: 20px;
}
